import { motion } from "framer-motion";

import { staggerContainer } from "@/utils/motion";
import { styles } from "@/utils/styles";

type SectionWrapperProps = (Component: React.FC, idName: string) => React.FC;

const SectionWrapper: SectionWrapperProps = (Component, idName) =>
	function HOC() {
		return (
			<motion.section
				variants={staggerContainer()}
				initial="hidden"
				whileInView="show"
				viewport={{ once: true, amount: 0.1 }}
				className={`${styles.padding} max-w-7xl mx-auto relative z-0`}
			>
				<span className="hash-span bg" id={idName}>
					&nbsp;
				</span>
				<Component />
			</motion.section>
		);
	};

export default SectionWrapper;
