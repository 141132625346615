export const userDetails = {
	email: "francoispolo9@gmail.com",
	bento: "https://bento.me/andasan",
	github: "https://github.com/andasan",
	linkedin: "https://www.linkedin.com/in/francoispolo",
};

export { technologies, introSummary, services } from "./content";
export { experiences } from "./experience";
export {
	fadeIn,
	slideIn,
	staggerContainer,
	staggerHero,
	textVariant,
	zoomIn,
} from "../utils/motion";
export { navLinks } from "./navigation";
export { projects } from "./projects";
export type { Project, Tag } from "./projects";
