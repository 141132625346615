import { cn } from "@/utils";
import { motion } from "framer-motion";
import Image from "next/image";
import { Tilt } from "react-next-tilt";

import { github, video, webicon } from "@/assets";
import SectionWrapper from "@/components/modules/wrapper/SectionWrapper";
import { projects } from "@/constants";
import type { Project } from "@/constants";
import { useComponentOnScreen } from "@/utils/hooks";
import { fadeIn, textVariant } from "@/utils/motion";
import { styles } from "@/utils/styles";

const colorsGradients = {
	blue: "bg-gradient-to-t from-blue-600 via-blue-700 to-blue-800 text-transparent bg-clip-text",
	green:
		"bg-gradient-to-t bg-gradient-to-t from-green-600 via-green-700 to-green-800 text-transparent bg-clip-text",
	yellow:
		"bg-gradient-to-t from-yellow-600 via-yellow-700 to-yellow-800 text-transparent bg-clip-text",
	orange:
		"bg-gradient-to-t from-orange-600 via-orange-700 to-orange-800 text-transparent bg-clip-text",
};

type ProjectCardProps = Project & { index: number };

const ProjectCard = ({
	index,
	name,
	description,
	tags,
	image,
	source_code_link,
	deployed_site_link,
	video_demo_link,
}: ProjectCardProps) => {
	return (
		<Tilt className="rounded-2xl">
			<motion.div
				variants={fadeIn("up", "spring", index * 0.5, 0.75)}
				className="bg-gray-800 bg-opacity-70 backdrop-blur-md p-5 rounded-2xl lg:w-[360px] w-full flex-1 lg:min-h-[550px]"
			>
				<div className="relative w-full h-[230px]">
					<Image
						src={image}
						alt={name}
						className="w-full h-full object-cover rounded-2xl"
						width={500}
						height={300}
					/>
					<div className="absolute inset-0 flex justify-end m-3 card-img_hover gap-2">
						{deployed_site_link && (
							<div
								onClick={() => window.open(deployed_site_link, "_blank")}
								onKeyDown={() => window.open(deployed_site_link, "_blank")}
								className="black-gradient w-10 h-10 rounded-full flex justify-center items-center cursor-pointer"
							>
								<Image
									src={webicon.src}
									alt="url-link"
									className="w-1/2 h-1/2 object-contain"
									width={20}
									height={20}
								/>
							</div>
						)}

						{source_code_link && (
							<div
								onClick={() => window.open(source_code_link, "_blank")}
								onKeyDown={() => window.open(source_code_link, "_blank")}
								className="black-gradient w-10 h-10 rounded-full flex justify-center items-center cursor-pointer"
							>
								<Image
									src={github.src}
									alt="github"
									className="w-1/2 h-1/2 object-contain"
									width={20}
									height={20}
								/>
							</div>
						)}

						{video_demo_link && (
							<div
								onClick={() => window.open(video_demo_link, "_blank")}
								onKeyDown={() => window.open(video_demo_link, "_blank")}
								className="black-gradient w-10 h-10 rounded-full flex justify-center items-center cursor-pointer"
							>
								<Image
									src={video.src}
									alt="github"
									className="w-1/2 h-1/2 object-contain"
									width={20}
									height={20}
								/>
							</div>
						)}
					</div>
				</div>

				<div className="mt-5">
					<h3 className="text-white font-bold text-[24px]">{name}</h3>
					<p className="mt-2 text-secondary text-[14px]">{description}</p>
				</div>

				<div className="mt-4 flex flex-wrap gap-2">
					{tags.map(({ name, color }) => {
						return (
							<p
								key={name}
								className={cn("text-[14px]", colorsGradients[color])}
							>
								#{name}
							</p>
						);
					})}
				</div>
			</motion.div>
		</Tilt>
	);
};

const Works = () => {
	const ref = useComponentOnScreen("works");
	return (
		<section id="works" ref={ref}>
			<div className="rounded-xl bg-gray-900 bg-opacity-50 px-16 py-10 shadow-lg backdrop-blur-md max-sm:px-8">
				<motion.div variants={textVariant()}>
					<p className={styles.sectionSubText}>My works</p>
					<h2 className={styles.sectionHeadText}>Projects</h2>
				</motion.div>

				<motion.div
					variants={fadeIn("", "", 0.1, 1)}
					// className="mt-3 text-secondary text-[17px] max-w-3xl leading-[30px]"
					className="mt-4 text-secondary leading-[30px]"
				>
					My hobbies revolve around Photography, Coding and Designing Web/Mobile
					Applications. I try my hand on photo manipulation at times and dive
					into post processing. I've recently been fiddling with After Effects
					and play around with Motion Animation, I'd like to get a tablet
					someday and relive the time I've sketched. Bringing my ideas into
					reality has been very challenging, but the sense of completion is very
					rewarding. Along this journey of conceptual design to deployment
					always has road blocks, I find it really engaging that I learn new
					things as I solve these blocks. Unrelated to my skills, I am very much
					a beer enthusiast. I may not have a liver of steel but a good beer is
					always a must. Without futher ado, here are my projects...
				</motion.div>
			</div>

			<div className="lg:mt-20 mt-5 flex flex-wrap gap-7 sm:justify-center">
				{projects.map((project, index) => (
					<ProjectCard key={project.id} {...project} index={index} />
				))}
			</div>
		</section>
	);
};

export default SectionWrapper(Works, "works");
